import { FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const passwordsDoNotMatch = (): ValidatorFn => {
  return (form ): ValidationErrors | null  => {
    const password = form.get('password')?.value;
    const repeatPassword = form.get('repeatPassword')?.value;

    return password && repeatPassword && password !== repeatPassword
      ? {passwordsDoNotMatch: true} : null
  }
}

export const emptyEmailAndPassword = (): ValidatorFn => {
  return (form ): ValidationErrors | null  => {
    const email = form.get('email')?.value as string;
    const phone = form.get('phone')?.value as string;

    return email.trim() == '' && phone.trim() == ''
      ? {emptyEmailAndPassword: true} : null
  }
}

export const emailOrPhone = Validators.compose([Validators.pattern(/(^[^@\s]+@[^@\s]+\.[^@\s]+$)|(^7\d{10}$)/), Validators.required]);

export const phone = Validators.pattern(/^7\d{10}$/);

export function isPhone(value?: string): boolean {
  return new FormControl(value, phone).valid;
}

export const otp = Validators.compose([Validators.minLength(6), Validators.maxLength(6), Validators.required]);

export const password = Validators.compose([Validators.minLength(8), Validators.required]);
import { createAction, props } from '@ngrx/store';
import { TokenResponse } from "../../service/auth.service";
import { EmailOrPhone, PasswordForm, SigninForm, SignupForm, VerificationForm } from "../../login-screen/forms";

export enum AuthActionTypes {
  SIGNUP = '[Auth] Signup',
  CHECK = '[Auth] Check',
  AUTH_SUCCESS = '[Auth] Auth Success',
  LOGIN = '[Auth] Login',
  CODE_VERIFY = '[Auth] Code verify',
  CODE_RESEND = '[Auth] Resend code',
  CODE_CAN_RESEND  = '[Auth] Code can resend',
  LOGOUT = '[Auth] LOGOUT',
  SET_PASSWORD = '[Auth] Set Password',
  ACTIVATE_TIMER = '[Auth] Activate timer',
  UPDATE_REMAINING_TIME = '[Auth] Update remaining time',
  RESTORE_SAVED_DATA = '[Auth] Restore saved data'
}

export const check = createAction(
  AuthActionTypes.CHECK
);

export const login = createAction(
  AuthActionTypes.LOGIN,
  props<SigninForm>()
);

export const authSuccess = createAction(
  AuthActionTypes.AUTH_SUCCESS,
  props<TokenResponse>()
);

export const logout = createAction(
  AuthActionTypes.LOGOUT
);

export const signup = createAction(
  AuthActionTypes.SIGNUP,
  props<SignupForm>()
);

export const codeVerify = createAction(
  AuthActionTypes.CODE_VERIFY,
  props<VerificationForm>()
);

export const resendCode = createAction(
  AuthActionTypes.CODE_RESEND,
  props<EmailOrPhone>()
);

export const canResend = createAction(
  AuthActionTypes.CODE_CAN_RESEND,
  props<{canResend: boolean}>()
);

export const activateTimer = createAction(
  AuthActionTypes.ACTIVATE_TIMER,
  props<{time: number}>()
);

export const updateRemainingTime = createAction(
  AuthActionTypes.UPDATE_REMAINING_TIME,
  props<{time: number}>()
);

export const setPassword = createAction(
  AuthActionTypes.SET_PASSWORD,
  props<PasswordForm>()
);

export const restoreSavedData = createAction(
  AuthActionTypes.RESTORE_SAVED_DATA
);
